import {
  FormPageData,
  PlanWithDescription,
  ServicePaymentDetails,
  TFunction,
} from '../../../types/types';
import { mapCatalogServiceToService } from '../../mappers/service.mapper';
import { FormApi } from '../../../api/FormApi';
import {
  getDefaultPaymentOptionId,
  getFirstAvailablePaymentOption,
} from '../../payment';
import { mapServicePaymentDto } from '@wix/bookings-uou-mappers';
import { initiateDynamicPriceState } from '../initiateDynamicPriceState/initiateDynamicPriceState';
import { getServiceSlotIdentifier } from '../..';
import { getDefaultPaymentType, getServicePlans } from '../utils/utils';
import { SlotService } from '../types';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { CatalogData } from '../../../api/types';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ISettingsGetter } from '@wix/tpa-settings';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { IFlowApiAdapter } from '../../flow-api-adapter/types';

export function mapNestedSlotToServices({
  formSelectedSlot,
  catalogData,
  memberships,
  numberOfSessions,
  serviceOptionsAndVariants,
  formPageData,
  bookingsLineItemOptions,
  isCart,
  formApi,
  wixSdkAdapter,
  settings,
  isPricingPlanInstalled,
  t,
  summaryPaymentDetails,
  publicPlans,
  benefitsWithPlanInfo,
}: {
  formSelectedSlot: FormSelectedSlot | undefined;
  catalogData: CatalogData | undefined;
  memberships:
    | {
        [key: string]: ListEligibleMembershipsResponse;
      }
    | undefined;
  numberOfSessions: { [key: string]: number };
  serviceOptionsAndVariants:
    | {
        [key: string]: ServiceOptionsAndVariants | undefined;
      }
    | undefined;
  formPageData: FormPageData;
  bookingsLineItemOptions: BookingsLineItemOption[] | undefined;
  isCart: boolean;
  formApi: FormApi;
  wixSdkAdapter: IFlowApiAdapter;
  settings: {
    get: ISettingsGetter;
  };
  isPricingPlanInstalled: boolean | undefined;
  t: TFunction;
  summaryPaymentDetails: { totalPrice: number; payLater: number };
  publicPlans: PlanWithDescription[] | undefined;
  benefitsWithPlanInfo: BenefitWithPlanInfo[] | undefined;
}) {
  const slotServicesArray: SlotService[] = (formSelectedSlot?.nestedSlots || [])
    .map((slot): SlotService => {
      const serviceSlotIdentifier = getServiceSlotIdentifier(slot);
      const serviceInfo = catalogData?.serviceList.find(
        (service) => service.service.service?.id === slot.serviceId,
      );

      if (!serviceInfo || !catalogData) {
        return {} as any;
      }

      const slotMemberships = memberships?.[serviceSlotIdentifier];

      const service = mapCatalogServiceToService({
        serviceInfo,
        formSelectedSlot: formSelectedSlot!,
        memberships: memberships?.[serviceSlotIdentifier]!,
        numberOfSessions: numberOfSessions[slot.serviceId],
        serviceId: slot.serviceId,
        isDynamicPriceEnabled: !!(
          serviceOptionsAndVariants?.[serviceSlotIdentifier] ||
          formPageData.dynamicPricePreSelection?.[serviceSlotIdentifier]
        ),
        bookingsLineItemOptions,
        isCart,
        slot,
      });

      const dynamicPriceInfo = initiateDynamicPriceState({
        service,
        dynamicPricePreSelection:
          formPageData.dynamicPricePreSelection?.[serviceSlotIdentifier],
        formApi,
        wixSdkAdapter,
        dateRegionalSettingsLocale:
          catalogData?.businessInfo.dateRegionalSettingsLocale!,
        serviceOptionsAndVariants:
          serviceOptionsAndVariants?.[serviceSlotIdentifier],
      });

      let possiblePlans: PlanWithDescription[] | undefined;

      if (publicPlans) {
        possiblePlans = getServicePlans(
          service.id,
          publicPlans,
          benefitsWithPlanInfo,
        );
      }

      const isDynamicPricingCustomOptions = !!dynamicPriceInfo?.customOptions;

      const defaultPaymentOptionId = getDefaultPaymentOptionId({
        settings,
        servicePayment: service.payment,
        memberships: slotMemberships,
        isPricingPlanInstalled: isPricingPlanInstalled!,
        isDynamicPricingCustomOptions,
        possiblePlans,
      });

      const selectedPaymentOption = getFirstAvailablePaymentOption({
        service,
        memberships: slotMemberships,
        isPricingPlanInstalled: isPricingPlanInstalled!,
        businessInfo: catalogData!.businessInfo,
        selectedPaymentOptionId: defaultPaymentOptionId,
        isDynamicPreferenceType: dynamicPriceInfo?.isDynamicPreferenceType!,
        t,
        settings,
        benefitsWithPlanInfo,
      });

      const paymentDetails = mapServicePaymentDto(
        serviceInfo.service,
      ) as ServicePaymentDetails;
      summaryPaymentDetails.totalPrice += paymentDetails.price;
      summaryPaymentDetails.payLater += paymentDetails.minCharge;

      const selectedPaymentType = getDefaultPaymentType(
        settings,
        service?.paymentTypes,
      );

      return {
        service,
        memberships: slotMemberships,
        nestedSlot: slot,
        dynamicPriceInfo,
        selectedPaymentType,
        selectedPaymentOption,
        paymentDetails,
        ...(possiblePlans ? { possiblePlans } : {}),
      };
    })
    .filter((service) => Object.keys(service).length);

  if (!slotServicesArray.length) {
    throw new Error('bad data - missing services');
  }

  const slotServices: { [key: string]: SlotService } = {};
  slotServicesArray.reduce((acc, current) => {
    slotServices[getServiceSlotIdentifier(current.nestedSlot)] = current;
    return slotServices;
  }, slotServices);
  return { slotServices, firstSlotServices: slotServicesArray[0] };
}
