import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { BusinessInfo, IncompleteFormSelectedSlot } from '../../../types/types';
import { mapBusinessResponseToBusinessInfo } from '../../../utils/mappers/businessInfo.mapper';
import { CatalogData, ExtendedPolicy, OnError, StaffMember } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import { serviceV2ToCatalogService } from '@wix/bookings-cc-bo-common/mappers';
import { query as resourceQuery } from '@wix/ambassador-bookings-catalog-v1-resource/http';
import { Resource } from '@wix/ambassador-bookings-catalog-v1-resource/types';
import { BookingPolicy } from '@wix/ambassador-bookings-services-v2-service/types';

type GetCatalogDataArgs = {
  formSelectedSlot?: FormSelectedSlot;
  onError?: OnError;
};

export const getCatalogData = async ({
  catalogServer,
  reportError,
  authorization,
  formSelectedSlot,
  onError,
  httpClient,
}: GetCatalogDataArgs & FormApiContext): Promise<CatalogData> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const [servicesData, resources, businessInfoResponse] =
          await Promise.all([
            formSelectedSlot?.nestedSlots.length
              ? httpClient.request(
                  queryServices({
                    query: {
                      filter: {
                        id: {
                          $in: formSelectedSlot?.nestedSlots.map(
                            (slot) => slot.serviceId,
                          ),
                        },
                      },
                    },
                  }),
                )
              : Promise.resolve({ data: { services: [] } }),
            httpClient.request(
              resourceQuery({
                query: {
                  filter: getResourcesFilter({
                    formSelectedSlot,
                  }),
                },
              }),
            ),
            catalogServer
              .BusinessCatalog()({ Authorization: authorization })
              .get({
                suppressNotFoundError: false,
              }),
          ]);

        const services = servicesData.data.services?.map((service) => {
          const oldTypeService = serviceV2ToCatalogService({
            service,
            resources: resources.data.resources!,
          });

          if (oldTypeService.service && oldTypeService.service.policy) {
            (oldTypeService.service.policy as ExtendedPolicy).id =
              service.bookingPolicy!.id;
            (
              oldTypeService.service.policy as ExtendedPolicy
            ).policyDescription =
              service.bookingPolicy!.customPolicyDescription?.description;

            (
              oldTypeService.service.policy as BookingPolicy
            ).cancellationFeePolicy =
              service.bookingPolicy!.cancellationFeePolicy;

            (
              oldTypeService.service.policy as BookingPolicy
            ).saveCreditCardPolicy =
              service.bookingPolicy!.saveCreditCardPolicy;
          }

          return oldTypeService;
        });

        const businessInfo: BusinessInfo =
          mapBusinessResponseToBusinessInfo(businessInfoResponse);

        const serviceList = services!.map((service) => {
          const slot = formSelectedSlot?.nestedSlots.find(
            (nestedSlot) => nestedSlot.serviceId === service?.service?.id,
          );
          const serviceResourcesIds =
            service?.resources?.map((resource) => resource.id) || [];
          const relevantResources = resources.data.resources!.filter(
            (resource) =>
              slot?.resource?.id || serviceResourcesIds.includes(resource.id!),
          );
          const staffMembers = relevantResources.map(
            mapResourcesV2ResponseToStaffMember,
          );

          return {
            service,
            staffMembers,
          };
        });

        return {
          serviceList,
          businessInfo,
          activeFeatures: businessInfoResponse.activeFeatures!,
        };
      },
      mapError: (e) => ({
        error: ServerErrorType.INVALID_CATALOG_DATA,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }

  return data!;
};

const getResourcesFilter = ({
  formSelectedSlot,
}: {
  formSelectedSlot?: IncompleteFormSelectedSlot;
}): Record<string, any> => {
  const filter: { [key: string]: any } = {};
  let isMissingResource = false;
  const resourceIds =
    formSelectedSlot?.nestedSlots.map((slot) => {
      if (!slot.resource?.id) {
        isMissingResource = true;
      }
      return slot.resource?.id;
    }) || [];

  if (isMissingResource || resourceIds.length === 0) {
    filter['resource.tags'] = { $hasSome: ['staff'] };
  } else {
    filter['resource.id'] = { $in: resourceIds };
  }

  return filter;
};

function mapResourcesV2ResponseToStaffMember(
  resourceResponse: Resource,
): StaffMember {
  return {
    id: resourceResponse.id!,
    name: resourceResponse.name!,
  };
}
