import { CreateActionParams } from '../actions';
import { bookingsUouBookingsFormClicks1052 } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  FormClickAction,
  FormClickActionValue,
} from '../../../../types/biLoggerTypes';
import { SlotService } from '../../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../../utils';
import { PaymentMethod } from '../../../../types/types';

export type setSelectedPaymentType = (
  service: SlotService,
  selectedPaymentType: PaymentMethod,
) => void;

export function createSetSelectedPaymentTypeAction({
  getControllerState,
  internalActions: { calculatePaymentDetails, updateServiceSlot },
  context: { biLogger },
}: CreateActionParams): setSelectedPaymentType {
  return async (service: SlotService, selectedPaymentType: PaymentMethod) => {
    const [state] = getControllerState();

    updateServiceSlot(
      {
        selectedPaymentType,
      },
      getServiceSlotIdentifier(service.nestedSlot),
    );
    biLogger?.report(
      bookingsUouBookingsFormClicks1052({
        action: FormClickAction.Payment,
        value:
          selectedPaymentType === PaymentMethod.MEMBERSHIP
            ? FormClickActionValue.PricingPlan
            : FormClickActionValue.SingleSessions,
      }),
    );

    await calculatePaymentDetails({
      couponCode: state.couponInfo.appliedCoupon?.couponCode,
    });
  };
}
