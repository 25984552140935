import { PlatformIdentifiers } from '../../../types/types';
import { FormApiContext } from '../utils';

export const isMembersAreaInstalled = async ({
  flowApi,
}: FormApiContext): Promise<boolean> => {
  try {
    const isMaInstalled =
      !!(await flowApi.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
        sectionId: PlatformIdentifiers.MEMBER_INFO_SECTION_ID,
        appDefinitionId: PlatformIdentifiers.MEMBER_ACCOUNT_INFO_WIDGET_ID,
      }));

    if (isMaInstalled) {
      return true;
    }

    return !!(await flowApi.controllerConfig.wixCodeApi.site.isAppSectionInstalled(
      {
        sectionId: PlatformIdentifiers.MEMBER_PAGE_SECTION_ID,
        appDefinitionId: PlatformIdentifiers.MEMBER_AREA_PAGE,
      },
    ));
  } catch (e) {
    console.error('Failed to call isMembersAreaInstalled', e);
    return false;
  }
};
