import {
  FormApiContext,
  mapSlotLocationTypeToServiceLocationType,
  removeTimezoneOffsetFromLocalDateString,
} from '../utils';
import {
  ListMultiServiceAvailabilityTimeSlotsResponse,
  Location,
  Service,
} from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import { listMultiServiceAvailabilityTimeSlots } from '@wix/ambassador-bookings-availability-v2-time-slot/http';
import { FormNestedSlot } from '../../../types/formSelectedSlots';

export const STAFF_RESOURCE_TYPE_ID = '1cd44cf8-756f-41c3-bd90-3e2ffcaf1155';

export type GetMultiServiceAvailabilityTimeSlotsArgs = {
  formNestedSlots: FormNestedSlot[];
};

export const getMultiServiceAvailabilityTimeSlots = async ({
  httpClient,
  formNestedSlots,
}: GetMultiServiceAvailabilityTimeSlotsArgs &
  FormApiContext): Promise<ListMultiServiceAvailabilityTimeSlotsResponse> => {
  const fromLocalDate = removeTimezoneOffsetFromLocalDateString(
    formNestedSlots[0].startDate,
  );
  const toLocalDate = removeTimezoneOffsetFromLocalDateString(
    formNestedSlots[formNestedSlots.length - 1].endDate,
  );
  const services: Service[] = formNestedSlots.map((slot) => ({
    serviceId: slot.serviceId,
    includeResourceTypeIds: [STAFF_RESOURCE_TYPE_ID],
  }));

  const { data: response } = await httpClient.request(
    listMultiServiceAvailabilityTimeSlots({
      fromLocalDate,
      toLocalDate,
      services,
      timeZone: formNestedSlots[0].timezone,
      location: {
        ...(formNestedSlots[0].location as Location),
        locationType: mapSlotLocationTypeToServiceLocationType(
          formNestedSlots[0].location?.locationType,
        ),
      },
    }),
  );

  return response;
};
